import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

const Organizations = (props) => {
  return (
    <div>
      <section className="relative">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div aria-hidden="true" className="absolute inset-0 -z-10">
            <canvas
              width="1152"
              height="667"
              style={{ width: "1152px", height: "667px" }}
            ></canvas>
          </div>
          <div
            className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/glow-top-2.svg"
              className="max-w-none"
              width="2143"
              height="737"
              alt="Hero Illustration"
            />
          </div>
          <div className="pt-32 md:pt-52 pb-12 md:pb-20">
            <div className="mx-auto text-center">
              <div className="mb-6" data-aos="fade-down">
                <h1
                  className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 aos-init aos-animate"
                  data-aos="fade-down"
                >
                  For Organizations
                </h1>
              </div>
            </div>
            <div className="max-w-3xl text-center mx-auto pb-12 md:pb-16">
              <p>
                Email us to get a flat installation rate for your entire
                organization:
                <br />
                <br />
                <b>sales@toophishy.com</b>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Organizations);
