import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";

import Account from "./Account";
import Cancel from "./Cancel";
import Prices from "./Prices";
import Register from "./Register";
import Checkout from "./Checkout";
import PrivacyPolicy from "./PrivacyPolicy";
import HowItWorks from "./HowItWorks";
import TermsOfService from "./TermsOfService";
import BeenScammed from "./BeenScammed";
import About from "./About";
import Support from "./Support";
import Organizations from "./Organizations";

function App(props) {
  return (
    <Switch>
      <Route exact path="/">
        <Register />
      </Route>
      <Route path="/how-it-works">
        <HowItWorks />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/support">
        <Support />
      </Route>
      <Route path="/for-organizations">
        <Organizations />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/been-scammed">
        <BeenScammed />
      </Route>
      <Route path="/terms-of-service">
        <TermsOfService />
      </Route>
      <Route path="/prices">
        <Prices />
      </Route>
      <Route path="/subscribe">
        <Prices />
      </Route>
      <Route path="/account">
        <Account />
      </Route>
      <Route path="/cancel">
        <Cancel />
      </Route>
    </Switch>
  );
}

export default App;
