import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

const AccountSubscription = ({ subscription }) => {
  return (
    <section className="relative">
      <div
        className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none -z-10"
        aria-hidden="true"
      >
        <img
          src="images/triangle-glow.svg"
          className="max-w-none"
          width="1440"
          height="450"
          alt="Page Illustration"
        />
      </div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <div className="mb-5">
              <a href="/" className="inline-flex">
                <img
                  className="relative"
                  src="images/logo.svg"
                  width="42"
                  height="42"
                  alt="Stellar"
                />
              </a>
            </div>
            <h4 className="h2 mb-5 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
              {subscription.status === "active" ||
              subscription.status === "trialing"
                ? "Setup Successful"
                : "Account"}
            </h4>

            <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
              {subscription.status === "active" ||
              subscription.status === "trialing" ? (
                <div>
                  <a
                    className="text-blue-500 hover:text-blue-600"
                    href="https://workspace.google.com/marketplace/app/too_phishy/802749066565"
                  >
                    Too Phishy
                  </a>{" "}
                  is all set to work with your Gmail™ inbox.
                </div>
              ) : (
                <div>Account Status: {subscription.status}</div>
              )}
            </p>

            <div className="max-w-sm mx-auto">
              <div className="space-y-4">
                <div>
                  <Link
                    className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                    to={{
                      pathname: "/cancel",
                      state: { subscription: subscription.id },
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Account = () => {
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { subscriptions } = await fetch("/subscriptions").then((r) =>
        r.json(),
      );

      setSubscriptions(subscriptions.data);
    };
    fetchData();
  }, []);

  if (!subscriptions) {
    return "";
  }

  return (
    <div>
      <div id="subscriptions">
        {subscriptions.map((s) => {
          return <AccountSubscription key={s.id} subscription={s} />;
        })}
      </div>
    </div>
  );
};

export default withRouter(Account);
