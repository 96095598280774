import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

const BeenScammed = (props) => {
  return (
    <div>
      <section className="relative">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div aria-hidden="true" className="absolute inset-0 -z-10">
            <canvas
              width="1152"
              height="667"
              style={{ width: "1152px", height: "667px" }}
            ></canvas>
          </div>
          <div
            className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/glow-top-2.svg"
              className="max-w-none"
              width="2143"
              height="737"
              alt="Hero Illustration"
            />
          </div>
          <div className="pt-32 md:pt-52 pb-12 md:pb-20">
            <div className="mx-auto text-center">
              <div className="mb-6" data-aos="fade-down">
                <h1
                  className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 aos-init aos-animate"
                  data-aos="fade-down"
                >
                  Have I been scammed?
                </h1>
              </div>
            </div>
            <div className="max-w-3xl mx-auto pb-12 md:pb-16">
              <p>
                If you've given any of the following pieces of personal
                information to someone online recently, the safest thing to do
                is call them right now. If you can't call them back, or if you
                hear a new voice when you call back the number they're on,
                they're likely not who they said they were.
                <ol type="A" className="indent">
                  <li>1. Your Social Security Number</li>
                  <li>2. Your banking routing number</li>
                  <li>2. Your credit card details</li>
                </ol>
                <br />
                As John McAfee once famously{" "}
                <a
                  className="text-blue-500 hover:text-blue-600"
                  href={"https://www.bbc.com/news/technology-24441931"}
                >
                  said
                </a>
                , "If someone sends me an email with a link, I'm not going there
                until I can call the person to verify that they sent me the
                email."
              </p>
            </div>

            <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
              <div className="w-full md:w-1/2 space-y-8">
                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Ok, I've definitely been scammed. Who should I contact?
                  </h4>
                  <p>
                    Don't worry, there are people to help you. But you have to
                    act quickly.
                    <br />
                    <br />
                    If you gave someone your banking routing number, you need to
                    call your bank immediately so they can close your bank
                    account and make a new one with a new routing number.
                    <br />
                    <br />
                    If you gave someone your credit card number, you need to
                    call your credit card company immediately so that they pause
                    the card.
                    <br />
                    <br />
                    If you've given someone your SSN, you need to freeze your
                    credit report at the major agencies. There's a great NYTimes
                    article about how to do so{" "}
                    <a
                      className="text-blue-500 hover:text-blue-600"
                      href={
                        "https://www.nytimes.com/2018/09/14/your-money/credit-freeze-free.html"
                      }
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/2 space-y-8">
                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Uh oh, I’m so embarrassed.
                  </h4>
                  <p>
                    Don’t be.{" "}
                    <a
                      className="text-blue-500 hover:text-blue-600"
                      href={
                        "https://socialcatfish.com/scamfish/state-of-internet-scams-2023/"
                      }
                    >
                      3.26
                    </a>{" "}
                    million people were scammed last year alone.
                    <br />
                    <br />A sophisticated phishing attempt can slip past all of
                    your cognitive defenses if it lands in your main inbox
                    during a moment of fatigue, distraction, or
                    absent-mindedness. And studies say even youth is not a guard
                    against attack, with Gen Z Americans{" "}
                    <a
                      className="text-blue-500 hover:text-blue-600"
                      href={
                        "https://www.vox.com/technology/23882304/gen-z-vs-boomers-scams-hacks"
                      }
                    >
                      3x more likely
                    </a>{" "}
                    to get caught up in an online scam than Boomers are.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Still don’t know what to do?
                  </h4>
                  <p>
                    Email us any time if you need help:
                    <br />
                    <br />
                    <b>support@toophishy.com</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(BeenScammed);
