import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";

const Prices = () => {
  const [price, setPrice] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    async function fetchPrice() {
      const { price } = await fetch("/price-config").then((r) => r.json());
      setPrice(price);
    }
    fetchPrice();
  }, [price]);

  const createSubscription = async (price) => {
    const { subscriptionId, clientSecret } = await fetch(
      "/create-subscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: price.id,
        }),
      },
    ).then((r) => r.json());

    setSubscriptionData({ subscriptionId, clientSecret });
  };

  if (subscriptionData) {
    return (
      <Redirect
        to={{
          pathname: "/subscribe",
          state: subscriptionData,
        }}
      />
    );
  }

  return (
    <div>
      {price && (
        <section className="relative">
          <div
            className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/triangle-glow.svg"
              className="max-w-none"
              width="1440"
              height="450"
              alt="Page Illustration"
            />
          </div>
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center">
                <div className="mb-5">
                  <a href="/" className="inline-flex">
                    <img
                      className="relative"
                      src="images/logo.svg"
                      width="42"
                      height="42"
                      alt="Stellar"
                    />
                  </a>
                </div>
                <h1 className="h2 mb-5 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
                  Subscribe
                </h1>
                <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                  {price.product.name}
                </p>
              </div>
              <div className="max-w-sm mx-auto">
                <div className="space-y-4">
                  <div>
                    <button
                      className="btn text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                      onClick={() => createSubscription(price)}
                    >
                      ${price.unit_amount / 100}/month{" "}
                      <span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                        →
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default withRouter(Prices);
