import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  PaymentElement,
  useElements,
  Elements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Subscribe = ({ location }) => {
  const [price, setPrice] = useState(null);
  const [messages, _setMessages] = useState("");
  const history = useHistory();

  useEffect(() => {
    async function fetchPrice() {
      const { price } = await fetch("/price-config").then((r) => r.json());
      setPrice(price);
    }
    fetchPrice();
  }, [price]);

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "";
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.origin}/account`,
      },
    });

    if (result.error) {
      // show error and collect new card details.
      setMessage(result.error.message);
    } else {
      history.push("/account");
    }
  };

  return (
    <div>
      {price && (
        <section className="relative">
          <div
            className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/triangle-glow.svg"
              className="max-w-none"
              width="1440"
              height="450"
              alt="Page Illustration"
            />
          </div>
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-md mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div style={{ textAlign: "left" }}>
                      <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                        ${price.unit_amount / 100}/month will be billed after
                        your 14-day free trial ends.
                      </p>
                    </div>

                    <PaymentElement />

                    <button className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group">
                      Subscribe
                    </button>

                    <div>{messages}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default withRouter(Subscribe);
