import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { CardElement } from "@stripe/react-stripe-js";

const Cancel = ({ location }) => {
  const [cancelled, setCancelled] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();

    await fetch("/cancel-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscriptionId: location.state.subscription,
      }),
    });

    setCancelled(true);
  };

  if (cancelled) {
    return <Redirect to={`/account`} />;
  }

  return (
    <section className="relative">
      <div
        className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none -z-10"
        aria-hidden="true"
      >
        <img
          src="images/triangle-glow.svg"
          className="max-w-none"
          width="1440"
          height="450"
          alt="Page Illustration"
        />
      </div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <div className="mb-5">
              <a href="/" className="inline-flex">
                <img
                  className="relative"
                  src="images/logo.svg"
                  width="42"
                  height="42"
                  alt="Stellar"
                />
              </a>
            </div>
            <h4 className="h2 mb-5 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">
              Cancel
            </h4>
            <div className="max-w-sm mx-auto">
              <div className="space-y-4">
                <button
                  className="btn text-sm text-white bg-purple-500 hover:bg-purple-600 w-full shadow-sm group"
                  onClick={handleClick}
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Cancel);
