import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

const About = (props) => {
  return (
    <div>
      <section className="relative">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div aria-hidden="true" className="absolute inset-0 -z-10">
            <canvas
              width="1152"
              height="667"
              style={{ width: "1152px", height: "667px" }}
            ></canvas>
          </div>
          <div
            className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/glow-top-2.svg"
              className="max-w-none"
              width="2143"
              height="737"
              alt="Hero Illustration"
            />
          </div>
          <div className="pt-32 md:pt-52 pb-12 md:pb-20">
            <div className="mx-auto text-center">
              <div className="mb-6">
                <h1 className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 aos-init aos-animate">
                  About Temnestra Security
                </h1>
              </div>
            </div>
            <div className="max-w-3xl mx-auto px-4">
              <div>
                <div className="relative max-w-xl mb-8 md:mb-0 mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center justify-center">
                  <div className="w-[512px] max-w-full shrink-0 md:order-1">
                    <p className="text-lg">
                      Temnestra Security is a software development lab based in
                      New York focused on consumer privacy and cybersecurity.
                      <br />
                      <br />
                      We originally created Too Phishy as an internal tool to
                      improve our abilities to detect cleverly made phishing
                      scams. We needed a tool — one that was easy and intuitive
                      — to help when our better judgement wasn't enough. It
                      worked so well that we decided to make it public.
                      <br />
                      <br />
                      You can read more about us on{" "}
                      <a
                        className="text-blue-500 hover:text-blue-600"
                        href={
                          "https://www.producthunt.com/products/too-phishy-for-gmail"
                        }
                      >
                        Product Hunt
                      </a>
                      .
                    </p>
                  </div>

                  <div className="w-full max-w-sm md:max-w-none md:mr-8">
                    <div className="relative px-4 py-4 -mx-8 md:mx-0">
                      <img
                        src="images/statenferry.jpeg"
                        className="md:max-w-none"
                        width="100%"
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(About);
