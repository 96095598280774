import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {withRouter} from 'react-router-dom';
import {
    Elements
} from '@stripe/react-stripe-js';
import Subscribe from "./Subscribe";

const Checkout = ({location}) => {

    const fetchStripePromise = async () => {
        const {publishableKey} = await fetch('/app-config').then(r => r.json());
        return loadStripe(publishableKey);
    };

    // Get the lookup key for the price from the previous page redirect.
    const [clientSecret] = useState(location.state.clientSecret);
    const [stripePromise, setStripePromise] = useState(async () => await fetchStripePromise())

    return (<div>
        {clientSecret && stripePromise && (<Elements stripe={stripePromise} options={{clientSecret}}>
            <Subscribe/>
        </Elements>)}
    </div>);
}
export default withRouter(Checkout);
