import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

const TermsOfService = (props) => {
  return (
    <div>
      <section className="relative">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div aria-hidden="true" className="absolute inset-0 -z-10">
            <canvas
              width="1152"
              height="667"
              style={{ width: "1152px", height: "667px" }}
            ></canvas>
          </div>
          <div
            className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/glow-top-2.svg"
              className="max-w-none"
              width="2143"
              height="737"
              alt="Hero Illustration"
            />
          </div>
          <div className="pt-32 md:pt-52 pb-12 md:pb-20">
            <div className="mx-auto text-center">
              <div className="mb-6" data-aos="fade-down">
                <h1
                  className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 aos-init aos-animate"
                  data-aos="fade-down"
                >
                  Terms of Service
                </h1>
              </div>
            </div>
            <div className="max-w-3xl mx-auto pb-12 md:pb-16">
              <div className="space-y-1">
                <h4 className="text-xl font-bold">Agreement to Terms</h4>
                <p>
                  These Terms of Use constitute a legally binding agreement made
                  between you, whether personally or on behalf of an entity
                  (“you”) and Temnestra Security LLC ("Company", “we”, “us”, or
                  “our”), concerning your access to and use of the Too
                  Phishy.com website as well as any other media form, media
                  channel, mobile website or mobile application related, linked,
                  or otherwise connected thereto (collectively, the “Site”). You
                  agree that by accessing the Site, you have read, understood,
                  and agreed to be bound by all of these Terms of Use. IF YOU DO
                  NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
                  EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
                  DISCONTINUE USE IMMEDIATELY.
                  <br />
                  <br />
                  Supplemental terms and conditions or documents that may be
                  posted on the Site from time to time are hereby expressly
                  incorporated herein by reference. We reserve the right, in our
                  sole discretion, to make changes or modifications to these
                  Terms of Use at any time and for any reason. We will alert you
                  about any changes by updating the “Last updated” date of these
                  Terms of Use, and you waive any right to receive specific
                  notice of each such change. It is your responsibility to
                  periodically review these Terms of Use to stay informed of
                  updates. You will be subject to, and will be deemed to have
                  been made aware of and to have accepted, the changes in any
                  revised Terms of Use by your continued use of the Site after
                  the date such revised Terms of Use are posted.
                  <br />
                  <br />
                  The information provided on the Site is not intended for
                  distribution to or use by any person or entity in any
                  jurisdiction or country where such distribution or use would
                  be contrary to law or regulation or which would subject us to
                  any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Site from other locations do so on their own initiative and
                  are solely responsible for compliance with local laws, if and
                  to the extent local laws are applicable.
                </p>
              </div>
            </div>

            <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
              <div className="w-full md:w-1/2 space-y-8">
                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Intellectual Property Rights
                  </h4>
                  <p>
                    Unless otherwise indicated, the Site is our proprietary
                    property and all source code, databases, functionality,
                    software, website designs, audio, video, text, photographs,
                    and graphics on the Site (collectively, the “Content”) and
                    the trademarks, service marks, and logos contained therein
                    (the “Marks”) are owned or controlled by us or licensed to
                    us, and are protected by copyright and trademark laws and
                    various other intellectual property rights and unfair
                    competition laws of the United States, international
                    copyright laws, and international conventions. The Content
                    and the Marks are provided on the Site “AS IS” for your
                    information and personal use only. Except as expressly
                    provided in these Terms of Use, no part of the Site and no
                    Content or Marks may be copied, reproduced, aggregated,
                    republished, uploaded, posted, publicly displayed, encoded,
                    translated, transmitted, distributed, sold, licensed, or
                    otherwise exploited for any commercial purpose whatsoever,
                    without our express prior written permission.
                    <br />
                    <br />
                    Provided that you are eligible to use the Site, you are
                    granted a limited license to access and use the Site and to
                    download or print a copy of any portion of the Content to
                    which you have properly gained access solely for your
                    personal, non-commercial use. We reserve all rights not
                    expressly granted to you in and to the Site, the Content and
                    the Marks.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">User Representations</h4>
                  <p>
                    By using the Site, you represent and warrant that: (1) you
                    have the legal capacity and you agree to comply with these
                    Terms of Use; (2) you are not a minor in the jurisdiction in
                    which you reside; ( 3) you will not access the Site through
                    automated or non-human means, whether through a bot, script,
                    or otherwise; ( 4 ) you will not use the Site for any
                    illegal or unauthorized purpose; and (5) your use of the
                    Site will not violate any applicable law or regulation.
                    <br />
                    <br />
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we have the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Site (or any portion thereof).
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Prohibited Activities</h4>
                  <p>
                    You may not access or use the Site for any purpose other
                    than that for which we make the Site available. The Site may
                    not be used in connection with any commercial endeavors
                    except those that are specifically endorsed or approved by
                    us.
                    <br />
                    <br />
                    As a user of the Site, you agree not to:
                    <ol type="A" className="indent">
                      <li>
                        1. Systematically retrieve data or other content from
                        the Site to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.
                      </li>
                      <li>
                        2.Trick, defraud, or mislead us and other users,
                        especially in any attempt to learn sensitive account
                        information such as user passwords.
                      </li>
                      <li>
                        3. Circumvent, disable, or otherwise interfere with
                        security-related features of the Site, including
                        features that prevent or restrict the use or copying of
                        any Content or enforce limitations on the use of the
                        Site and/or the Content contained therein.
                      </li>
                      <li>
                        4. Use any information obtained from the Site in order
                        to harass, abuse, or harm another person.
                      </li>
                      <li>
                        5. Make improper use of our support services or submit
                        false reports of abuse or misconduct.
                      </li>
                      <li>
                        6. Use the Site in a manner inconsistent with any
                        applicable laws or regulations.
                      </li>
                      <li>
                        7. Engage in unauthorized framing of or linking to the
                        Site.
                      </li>
                      <li>
                        8. Upload or transmit (or attempt to upload or to
                        transmit) viruses, Trojan horses, or other material,
                        including excessive use of capital letters and spamming
                        (continuous posting of repetitive text), that interferes
                        with any party’s uninterrupted use and enjoyment of the
                        Site or modifies, impairs, disrupts, alters, or
                        interferes with the use, features, functions, operation,
                        or maintenance of the Site.
                      </li>
                      <li>
                        9. Engage in any automated use of the system, such as
                        using scripts to send comments or messages, or using any
                        data mining, robots, or similar data gathering and
                        extraction tools.
                      </li>
                      <li>
                        10. Delete the copyright or other proprietary rights
                        notice from any Content.
                      </li>
                      <li>
                        11. Attempt to impersonate another user or person or use
                        the username of another user.
                      </li>
                      <li>12. Sell or otherwise transfer your profile.</li>
                      <li>
                        13. Upload or transmit (or attempt to upload or to
                        transmit) any material that acts as a passive or active
                        information collection or transmission mechanism,
                        including without limitation, clear graphics interchange
                        formats (“gifs”), 1×1 pixels, web bugs, cookies, or
                        other similar devices (sometimes referred to as
                        “spyware” or “passive collection mechanisms” or “pcms”).
                      </li>
                      <li>
                        14. Interfere with, disrupt, or create an undue burden
                        on the Site or the networks or services connected to the
                        Site.
                      </li>
                      <li>
                        15. Harass, annoy, intimidate, or threaten any of our
                        employees or agents engaged in providing any portion of
                        the Site to you.
                      </li>
                      <li>
                        16. Attempt to bypass any measures of the Site designed
                        to prevent or restrict access to the Site, or any
                        portion of the Site.
                      </li>
                      <li>
                        17. Copy or adapt the Site’s software, including but not
                        limited to Flash, PHP, HTML, JavaScript, or other code.
                      </li>
                      <li>
                        18. Decipher, decompile, disassemble, or reverse
                        engineer any of the software comprising or in any way
                        making up a part of the Site.
                      </li>
                      <li>
                        19. Use a buying agent or purchasing agent to make
                        purchases on the Site.
                      </li>
                      <li>
                        20. Make any unauthorized use of the Site, including
                        collecting usernames and/or email addresses of users by
                        electronic or other means for the purpose of sending
                        unsolicited email, or creating user accounts by
                        automated means or under false pretenses.
                      </li>
                      <li>
                        21. Use the Site as part of any effort to compete with
                        us or otherwise use the Site and/or the Content for any
                        revenue-generating endeavor or commercial enterprise.
                      </li>
                    </ol>
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Contribution License</h4>
                  <p>
                    You and the Site agree that we may access, store, process,
                    and use any information and personal data that you provide
                    following the terms of the Privacy Policy and your choices
                    (including settings).
                    <br />
                    <br />
                    By submitting suggestions or other feedback regarding the
                    Site, you agree that we can use and share such feedback for
                    any purpose without compensation to you.
                    <br />
                    <br />
                    We do not assert any ownership over your Contributions. You
                    retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights
                    associated with your Contributions. We are not liable for
                    any statements or representations in your Contributions
                    provided by you in any area on the Site. You are solely
                    responsible for your Contributions to the Site and you
                    expressly agree to exonerate us from any and all
                    responsibility and to refrain from any legal action against
                    us regarding your Contributions.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Site Management</h4>
                  <p>
                    We reserve the right, but not the obligation, to: (1)
                    monitor the Site for violations of these Terms of Use; (2)
                    take appropriate legal action against anyone who, in our
                    sole discretion, violates the law or these Terms of Use,
                    including without limitation, reporting such user to law
                    enforcement authorities; (3) in our sole discretion and
                    without limitation, refuse, restrict access to, limit the
                    availability of, or disable (to the extent technologically
                    feasible) any of your Contributions or any portion thereof;
                    (4) in our sole discretion and without limitation, notice,
                    or liability, to remove from the Site or otherwise disable
                    all files and content that are excessive in size or are in
                    any way burdensome to our systems; and (5) otherwise manage
                    the Site in a manner designed to protect our rights and
                    property and to facilitate the proper functioning of the
                    Site.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Privacy Policy</h4>
                  <p>
                    We care about data privacy and security. Please review our{" "}
                    <a
                      className="text-blue-500 hover:text-blue-600"
                      href={"/privacy-policy"}
                    >
                      Privacy Policy
                    </a>
                    . By using the Site, you agree to be bound by our Privacy
                    Policy, which is incorporated into these Terms of Use.
                    Please be advised the Site is hosted in the United States.
                    If you access the Site from any other region of the world
                    with laws or other requirements governing personal data
                    collection, use, or disclosure that differ from applicable
                    laws in the United States, then through your continued use
                    of the Site, you are transferring your data to the United
                    States, and you agree to have your data transferred to and
                    processed in the United States.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Term and Termination</h4>
                  <p>
                    These Terms of Use shall remain in full force and effect
                    while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION
                    OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE
                    DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO
                    AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP
                    ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                    INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                    REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                    TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                    TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE
                    ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
                    WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    <br />
                    <br />
                    If we terminate or suspend your account for any reason, you
                    are prohibited from registering and creating a new account
                    under your name, a fake or borrowed name, or the name of any
                    third party, even if you may be acting on behalf of the
                    third party. In addition to terminating or suspending your
                    account, we reserve the right to take appropriate legal
                    action, including without limitation pursuing civil,
                    criminal, and injunctive redress.
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/2 space-y-8">
                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Modifications and Interruptions
                  </h4>
                  <p>
                    We reserve the right to change, modify, or remove the
                    contents of the Site at any time or for any reason at our
                    sole discretion without notice. However, we have no
                    obligation to update any information on our Site. We also
                    reserve the right to modify or discontinue all or part of
                    the Site without notice at any time. We will not be liable
                    to you or any third party for any modification, price
                    change, suspension, or discontinuance of the Site.
                    <br />
                    <br />
                    We cannot guarantee the Site will be available at all times.
                    We may experience hardware, software, or other problems or
                    need to perform maintenance related to the Site, resulting
                    in interruptions, delays, or errors. We reserve the right to
                    change, revise, update, suspend, discontinue, or otherwise
                    modify the Site at any time or for any reason without notice
                    to you. You agree that we have no liability whatsoever for
                    any loss, damage, or inconvenience caused by your inability
                    to access or use the Site during any downtime or
                    discontinuance of the Site. Nothing in these Terms of Use
                    will be construed to obligate us to maintain and support the
                    Site or to supply any corrections, updates, or releases in
                    connection therewith.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Corrections</h4>
                  <p>
                    There may be information on the Site that contains
                    typographical errors, inaccuracies, or omissions, including
                    descriptions, pricing, availability, and various other
                    information. We reserve the right to correct any errors,
                    inaccuracies, or omissions and to change or update the
                    information on the Site at any time, without prior notice.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Disclaimer</h4>
                  <p>
                    THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
                    AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT
                    YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                    DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                    WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT
                    LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                    MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                    COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
                    WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY
                    OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                    INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY
                    OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
                    YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED
                    ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                    PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                    THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION
                    TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                    OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE
                    BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
                    ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                    KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                    TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
                    NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
                    FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
                    PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
                    WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                    OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
                    WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
                    YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                    AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
                    MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                    JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Limitations of Liability
                  </h4>
                  <p>
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                    BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                    PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                    OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE,
                    EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                    HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
                    REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                    LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
                    US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
                    ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                    LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                    SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT
                    APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Indemnification</h4>
                  <p>
                    You agree to defend, indemnify, and hold us harmless,
                    including our subsidiaries, affiliates, and all of our
                    respective officers, agents, partners, and employees, from
                    and against any loss, damage, liability, claim, or demand,
                    including reasonable attorneys’ fees and expenses, made by
                    any third party due to or arising out of: (1) use of the
                    Site; (2) breach of these Terms of Use; (3) any breach of
                    your representations and warranties set forth in these Terms
                    of Use; ( 4) your violation of the rights of a third party,
                    including but not limited to intellectual property rights;
                    or (5) any overt harmful act toward any other user of the
                    Site with whom you connected via the Site. Notwithstanding
                    the foregoing, we reserve the right, at your expense, to
                    assume the exclusive defense and control of any matter for
                    which you are required to indemnify us, and you agree to
                    cooperate, at your expense, with our defense of such claims.
                    We will use reasonable efforts to notify you of any such
                    claim, action, or proceeding which is subject to this
                    indemnification upon becoming aware of it.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">User Data</h4>
                  <p>
                    We will maintain certain data that you transmit to the Site
                    for the purpose of managing the performance of the Site, as
                    well as data relating to your use of the Site. Although we
                    perform regular routine backups of data, you are solely
                    responsible for all data that you transmit or that relates
                    to any activity you have undertaken using the Site. You
                    agree that we shall have no liability to you for any loss or
                    corruption of any such data, and you hereby waive any right
                    of action against us arising from any such loss or
                    corruption of such data.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Miscellaneous</h4>
                  <p>
                    These Terms of Use and any policies or operating rules
                    posted by us on the Site or in respect to the Site
                    constitute the entire agreement and understanding between
                    you and us. Our failure to exercise or enforce any right or
                    provision of these Terms of Use shall not operate as a
                    waiver of such right or provision. These Terms of Use
                    operate to the fullest extent permissible by law. We may
                    assign any or all of our rights and obligations to others at
                    any time. We shall not be responsible or liable for any
                    loss, damage, delay, or failure to act caused by any cause
                    beyond our reasonable control. If any provision or part of a
                    provision of these Terms of Use is determined to be
                    unlawful, void, or unenforceable, that provision or part of
                    the provision is deemed severable from these Terms of Use
                    and does not affect the validity and enforceability of any
                    remaining provisions. There is no joint venture,
                    partnership, employment or agency relationship created
                    between you and us as a result of these Terms of Use or use
                    of the Site. You agree that these Terms of Use will not be
                    construed against us by virtue of having drafted them. You
                    hereby waive any and all defenses you may have based on the
                    electronic form of these Terms of Use and the lack of
                    signing by the parties hereto to execute these Terms of Use.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Contact us</h4>
                  <p>
                    In order to resolve a complaint regarding the Site or to
                    receive further information regarding use of the Site,
                    please contact us at:
                    <br />
                    <br />
                    <b>support@toophishy.com</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(TermsOfService);
