import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

const PrivacyPolicy = (props) => {
  return (
    <div>
      <section className="relative">
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div aria-hidden="true" className="absolute inset-0 -z-10">
            <canvas
              width="1152"
              height="667"
              style={{ width: "1152px", height: "667px" }}
            ></canvas>
          </div>
          <div
            className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/glow-top-2.svg"
              className="max-w-none"
              width="2143"
              height="737"
              alt="Hero Illustration"
            />
          </div>
          <div className="pt-32 md:pt-52 pb-12 md:pb-20">
            <div className="mx-auto text-center">
              <div className="mb-6" data-aos="fade-down">
                <h1
                  className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 aos-init aos-animate"
                  data-aos="fade-down"
                >
                  Privacy Policy
                </h1>
              </div>
            </div>
            <div className="max-w-3xl mx-auto pb-12 md:pb-16">
              <p>
                This privacy policy discloses the privacy practices concerning
                the Too Phishy Gmail™ add-on and its accompanying website Too
                Phishy.com. This privacy policy applies solely to information
                collected by this application and will cover the following
                topics:
                <ol type="A" className="indent">
                  <li>
                    1. What specific personal information we collect from you
                    via the application, how we store this information, and who
                    it may potentially be shared with.
                  </li>
                  <li>
                    2. Who and how to contact someone to correct any
                    inaccuracies or to request your information be deleted from
                    our systems.
                  </li>
                </ol>
                <br />
                Too Phishy’s use and transfer of information received from
                Google APIs to any other app will adhere to{" "}
                <a
                  class="text-blue-500 hover:text-blue-600"
                  href={
                    "https://developers.google.com/terms/api-services-user-data-policy"
                  }
                >
                  Google API Services User Data Policy
                </a>
                , including the Limited Use requirements.
                <ul type="A" className="indent">
                  <li>
                    &#8226; The App will only use access to read Gmail™ message
                    bodies (including attachments), metadata, and headers to
                    provide a web email add-on that allows users to see
                    characteristics of all received emails, and will not
                    transfer this data to others unless doing so is necessary to
                    provide and improve these features, comply with applicable
                    law, or as part of a merger, acquisition, or sale of assets.
                  </li>
                  <li>
                    &#8226; The App will not use this Gmail™ data for serving
                    advertisements.
                  </li>
                  <li>
                    &#8226; The App will not allow humans to read this data
                    unless we have your affirmative agreement for specific
                    messages, doing so is necessary for security purposes such
                    as investigating abuse, to comply with applicable law, or
                    for the App’s internal operations and even then only when
                    the data have been aggregated and anonymized.
                  </li>
                </ul>
              </p>
            </div>

            <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
              <div className="w-full md:w-1/2 space-y-8">
                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    What information does our Too Phishy Gmail™ add-on collect?
                  </h4>
                  <p>
                    When using our apps developed for the Google Workspace
                    Marketplace and within the Google Workspace environment,
                    there is no advertising and no tracking of user data and
                    activity. All data* is stored on your Google Apps account
                    and is never transferred to Too Phishy apps or other
                    external service.
                    <br />
                    <br />
                    *With the exception of your email address. When you install
                    our apps from the Workspace Marketplace you agree to the
                    collection of your email address linked to your Google
                    account from which you installed the app. Your email is only
                    used to check if a valid license exists. If you do not have
                    a valid license then your email is never stored or
                    transferred to our servers. If you do have a valid license
                    then your email is stored securely for the sole purpose of
                    validating your license. Too Phishy apps does not share your
                    email to any third parties unless required by law.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    What information does our website, TooPhishy.com, collect?
                  </h4>
                  <p>
                    We receive, collect and store any information you enter on
                    our website TooPhishy.com or provide us in any other way. In
                    addition, we collect the Internet protocol (IP) address used
                    to connect your computer to the Internet; e-mail address;
                    computer and connection information and purchase history. We
                    may use software tools to measure and collect session
                    information, including page response times, length of visits
                    to certain pages, page interaction information, and methods
                    used to browse away from the page. We also collect
                    personally identifiable information (email); payment details
                    (including credit card information), comments, feedback,
                    product reviews, and recommendations.
                  </p>
                </div>
              </div>

              <div className="w-full md:w-1/2 space-y-8">
                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Where do we store your email address and payments
                    information?
                  </h4>
                  <p>
                    Our company is hosted on the Stripe.com platform. Stripe.com
                    provides us with the online platform that allows us to sell
                    our products and services to you. Your data may be stored
                    through Stripe.com’s data storage, databases and the general
                    Stripe.com applications. They store your data on secure
                    servers behind a firewall.
                    <br />
                    <br />
                    All direct payment gateways offered by Stripe.com and used
                    by our company adhere to the standards set by PCI-DSS as
                    managed by the PCI Security Standards Council, which is a
                    joint effort of brands like Visa, MasterCard, American
                    Express and Discover.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    What information do we share with third parties?
                  </h4>
                  <p>
                    We will not share your information with any third party
                    outside of our organization. We may contact you via email in
                    the future to tell you about specials, new products or
                    services, or changes to this privacy policy.
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">
                    Access to and Control Over Information
                  </h4>
                  <p>
                    You may opt out of any future contacts from us at any time.
                    You can do the following at any time by contacting us via
                    the email at{" "}
                    <span class="text-blue-500 hover:text-blue-600">
                      support@toophishy.com
                    </span>
                    <ul type="A" className="indent">
                      <li>&#8226; See what data we have about you, if any.</li>
                      <li>
                        &#8226; Change/correct any data we have about you.
                      </li>
                      <li>
                        &#8226; Have us delete any data we have about you.
                      </li>
                      <li>
                        &#8226; Express any concern you have about our use of
                        your data.
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="space-y-2">
                  <h4 className="text-xl font-bold">Updates</h4>
                  <p>
                    Our Privacy Policy may change from time to time and all
                    updates will be posted on this page.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(PrivacyPolicy);
