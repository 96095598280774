import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
const HowItWorks = (props) => {
  return (
    <div>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <main className="grow">
          <section className="relative">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
              <div aria-hidden="true" className="absolute inset-0 -z-10">
                <canvas
                  width="1152"
                  height="667"
                  style={{ width: "1152px", height: "667px" }}
                ></canvas>
              </div>
              <div
                className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
                aria-hidden="true"
              >
                <img
                  src="images/glow-top-2.svg"
                  className="max-w-none"
                  width="2143"
                  height="737"
                  alt="Hero Illustration"
                />
              </div>
              <div className="pt-32 md:pt-52">
                <div className="mx-auto text-center">
                  <div className="max-w-3xl mx-auto mb-6" data-aos="fade-down">
                    <h1
                      className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4 aos-init aos-animate"
                      data-aos="fade-down"
                    >
                      How history could have been different...
                    </h1>
                    <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                      Malicious actors are very good at making emails look
                      genuine. They write emails that impersonate your
                      colleagues, and duplicate the design of your favorite
                      sites to trick you into clicking links and sharing
                      personal information and passwords.
                      <br />
                      <br />
                      In fact, many famous hacks in history started with a
                      phishing email. But it doesn't have to be this way!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="tax-refund" className="mt-6" data-aos-id-4>
            <div className="relative max-w-7xl mx-auto">
              <div
                className="absolute inset-0 rounded-tr-[100px] mb-24 md:mb-0 bg-gradient-to-b from-slate-800 pointer-events-none -z-10"
                aria-hidden="true"
              ></div>

              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-6 pt-12 md:pt-20">
                  <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center justify-end">
                    <div className="w-[512px] max-w-full shrink-0 md:order-1">
                      <h2 className="h3 mb-4">
                        The IRS Tax Refund Scam Emails...if Too Phishy had
                        existed
                      </h2>
                      <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                        In 2021, malicious actors sent phishing emails seemingly{" "}
                        <a
                          className="text-blue-500 hover:text-blue-600"
                          href={"https://cofense.com/blog/claim-your-phish-now"}
                        >
                          from
                        </a>{" "}
                        the IRS as part of a{" "}
                        <a
                          className="text-blue-500 hover:text-blue-600"
                          href={
                            "https://abnormalsecurity.com/blog/irs-impersonation"
                          }
                        >
                          ploy
                        </a>{" "}
                        to collect victims' SSNs and drivers' license numbers.{" "}
                      </p>
                      <p className="text-lg mb-8">
                        You, if you had Too Phishy: "Hmmm, why is this email
                        sender pretending to be the IRS? 🤔"
                      </p>
                    </div>

                    <div className="w-full max-w-sm md:max-w-none md:mr-8 mt-8 md:mt-0">
                      <div className="relative -mx-8 md:mx-0">
                        <img
                          src="images/tax_refund.png"
                          className="md:max-w-none"
                          width="596"
                          alt="Features 03"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="sony" className="pt-36" data-aos-id-4>
            <div className="relative max-w-7xl mx-auto">
              <div
                className="absolute inset-0 rounded-tl-[100px] mb-24 md:mb-0 bg-gradient-to-b from-blue-600 pointer-events-none -z-10"
                aria-hidden="true"
              ></div>

              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-6 pt-12 md:pt-20">
                  <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center">
                    <div className="w-[512px] max-w-full shrink-0">
                      <h2 className="h3 mb-4">
                        The famous Sony Hack Phishing emails...if Too Phishy had
                        existed
                      </h2>

                      <div className="sm:columns-1 mb-8 space-y-8 sm:space-y-0">
                        <div>
                          <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                            Who can forget when North Korean hackers hacked into
                            Sony's network, publishing embarrassing emails,
                            scripts, and even the financial data of Sony
                            employees, all by{" "}
                            <a
                              className="text-blue-500 hover:text-blue-600"
                              href={
                                "https://www.justice.gov/opa/press-release/file/1092091"
                              }
                            >
                              using
                            </a>{" "}
                            a few phishing emails?
                          </p>
                          <p className="text-lg mb-8">
                            You: "Why is this Facebook login link pointing to a
                            website hosted in Korea? If I click, will I ever get
                            to see <i>The Interview</i> in theaters??"
                          </p>
                        </div>
                      </div>

                      <div className="max-w-xs mx-auto sm:max-w-none">
                        <div>
                          <a
                            className="btn-sm inline-flex items-center text-blue-50 bg-blue-500 hover:bg-blue-600 group shadow-sm"
                            href="/"
                          >
                            Sign up now
                            <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                              <svg
                                className="fill-current"
                                width="12"
                                height="10"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="w-full max-w-sm md:max-w-none md:ml-8 mt-8 md:mt-0">
                      <div className="relative -mx-8 md:mx-0">
                        <img
                          src="images/sony.png"
                          className="md:max-w-none ml-auto"
                          width="596"
                          alt="Features 04"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="docusign" className="pt-36" data-aos-id-4>
            <div className="relative max-w-7xl mx-auto">
              <div
                className="absolute inset-0 rounded-tr-[100px] mb-24 md:mb-0 bg-gradient-to-b from-slate-800 pointer-events-none -z-10"
                aria-hidden="true"
              ></div>

              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-6 pt-12 md:pt-20">
                  <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center justify-end">
                    <div className="w-[512px] max-w-full shrink-0 md:order-1">
                      <h2 className="h3 mb-4">
                        The DocuSign Scam Emails...if Too Phishy had existed
                      </h2>
                      <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                        In 2019, phishers used a{" "}
                        <a
                          className="text-blue-500 hover:text-blue-600"
                          href={
                            "https://blog.tcitechs.com/blog/docusign-scam-email/"
                          }
                        >
                          clever
                        </a>{" "}
                        Docusign clone email to trick users into downloading a
                        malicious trojan{" "}
                        <a
                          className="text-blue-500 hover:text-blue-600"
                          href={
                            "https://www.pcrisk.com/removal-guides/13288-docusign-email-virus"
                          }
                        >
                          known as
                        </a>{" "}
                        TrickBot.
                      </p>
                      <p className="text-lg mb-8">
                        You: "Hmmm, who's Jean Delacroix? 🤔 That doesn't sound
                        like anyone I know. Cool name, but I'm not clicking."
                      </p>

                      <div className="max-w-xs mx-auto sm:max-w-none mb-8">
                        <div>
                          <a
                            className="btn-sm inline-flex items-center text-blue-50 bg-blue-500 hover:bg-blue-600 group shadow-sm"
                            href="/"
                          >
                            Sign up now
                            <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                              <svg
                                className="fill-current"
                                width="12"
                                height="10"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="w-full max-w-sm md:max-w-none md:mr-8 mt-8 md:mt-0">
                      <div className="relative -mx-8 md:mx-0">
                        <img
                          src="images/docusign.png"
                          className="md:max-w-none"
                          width="596"
                          alt="Features 03"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="podesta" className="pt-36" data-aos-id-4>
            <div className="relative max-w-7xl mx-auto">
              <div
                className="absolute inset-0 rounded-tl-[100px] mb-24 md:mb-0 bg-gradient-to-b from-blue-600 pointer-events-none -z-10"
                aria-hidden="true"
              ></div>

              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-6 pt-12 md:pt-20">
                  <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center">
                    <div className="w-[512px] max-w-full shrink-0">
                      <h2 className="h3 mb-4">
                        Or <b>that</b> phishing email received by John
                        Podesta...if Too Phishy had existed
                      </h2>

                      <p className="text-lg text-slate-300 mb-8 aos-init aos-animate">
                        In 2016, a Russian hacking group{" "}
                        <a
                          className="text-blue-500 hover:text-blue-600"
                          href={
                            "https://www.cbsnews.com/news/the-phishing-email-that-hacked-the-account-of-john-podesta"
                          }
                        >
                          known as
                        </a>{" "}
                        Fancy Bear hacked into the network of the Democratic
                        National Committee using one simple phishing email.
                      </p>
                      <p className="text-lg mb-8">
                        You: "Why is someone in Ukraine telling me to reset my
                        Google password? And will this end Democracy as we know
                        it?"
                      </p>
                      <div className="max-w-xs mx-auto sm:max-w-none">
                        <div>
                          <a
                            className="btn-sm inline-flex items-center text-blue-50 bg-blue-500 hover:bg-blue-600 group shadow-sm"
                            href="/"
                          >
                            Sign up now
                            <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                              <svg
                                className="fill-current"
                                width="12"
                                height="10"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                              </svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="w-full max-w-sm md:max-w-none md:ml-8 mt-8 md:mt-0">
                      <div className="relative -mx-8 md:mx-0">
                        <img
                          src="images/podesta.png"
                          className="md:max-w-none ml-auto"
                          width="596"
                          alt="Features 04"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-36">
            <div className="relative max-w-7xl mx-auto">
              <div
                className="absolute inset-0 rounded-tr-[100px] bg-gradient-to-tr pointer-events-none -z-10"
                aria-hidden="true"
              ></div>

              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-6 pt-12 md:pt-20">
                  <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                    <div className="flex justify-center mb-8">
                      <div>
                        <img
                          className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                          src="images/logos-illustration.svg"
                          width="594"
                          height="639"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="relative">
                        <h2 className="h3 text-white text-center mb-4">
                          Install the Too Phishy Add-on for Gmail™ now and get
                          started in minutes
                        </h2>
                        <div className="max-w-xs mx-auto sm:max-w-none">
                          <div>
                            <a
                              className="btn-sm inline-flex items-center text-blue-50 bg-blue-500 hover:bg-blue-600 group shadow-sm"
                              href="/"
                            >
                              Sign up now
                              <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                <svg
                                  className="fill-current"
                                  width="12"
                                  height="10"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-center mb-24">
                      <div className="relative">
                        <img
                          src="images/logos.png"
                          width="720"
                          height="283"
                          alt="Logos"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default withRouter(HowItWorks);
