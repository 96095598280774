import React, { useEffect, useState } from "react";
import Swiper, { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import { Redirect } from "react-router-dom";

Swiper.use([Autoplay]);

const Register = (props) => {
  const [email, setEmail] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { customer, hasActiveSubscription } = await findExistingCustomer();
    setHasActiveSubscription(hasActiveSubscription);
    if (customer) {
      setCustomer(customer);
    } else {
      let { customer } = await createCustomer();
      setCustomer(customer);
    }
  };

  const findExistingCustomer = async () => {
    return await fetch("/get-customer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    }).then((r) => r.json());
  };

  const createCustomer = async () => {
    return await fetch("/create-customer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    }).then((r) => r.json());
  };

  useEffect(() => {
    const carousel = new Swiper(".clients-carousel", {
      slidesPerView: "auto",
      spaceBetween: 64,
      centeredSlides: true,
      loop: true,
      speed: 5000,
      noSwiping: true,
      noSwipingClass: "swiper-slide",
      autoplay: {
        delay: 0,
        disableOnInteraction: true,
      },
    });
  }, []);

  if (hasActiveSubscription) {
    return <Redirect to={{ pathname: "/account" }} />;
  } else if (customer) {
    return <Redirect to={{ pathname: "/prices" }} />;
  }

  return (
    <div>
      <section>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div aria-hidden="true" className="absolute inset-0 -z-10">
            <canvas
              width="1152"
              height="667"
              style={{ width: "1152px", height: "667px" }}
            ></canvas>
          </div>
          <div
            className="absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src="images/glow-top-2.svg"
              className="max-w-none"
              width="2143"
              height="737"
              alt="Hero Illustration"
            />
          </div>
          <div className="pt-32 pb-16 md:pt-52">
            <div className="max-w-3xl mx-auto text-center">
              <div className="pb-12" data-aos="fade-down">
                <div className="inline-flex relative before:absolute before:inset-0 before:bg-purple-500 before:blur-md">
                  <div
                    style={{ borderRadius: "9999px" }}
                    className="btn-sm py-0.5 text-slate-300 transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.purple.500),_theme(colors.purple.500))_padding-box,_linear-gradient(theme(colors.purple.500),_theme(colors.purple.200)_75%,_theme(colors.transparent)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                  >
                    <span className="relative inline-flex items-center">
                      Protect your inbox
                      <span className="tracking-normal text-purple-500 transition-transform duration-150 ease-in-out ml-1"></span>
                    </span>
                  </div>
                </div>
              </div>

              <h1
                className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-12 aos-init aos-animate"
                data-aos="fade-down"
              >
                The Only Phishing Plugin You Will Ever Need
              </h1>
              <div>
                <p className="pb-12">
                  Too Phishy is a phishing detection add-on for Gmail™ that
                  shows you an email’s hidden characteristics before you click.
                  Check out our{" "}
                  <a
                    className="text-blue-500 hover:text-blue-600"
                    href={"/how-it-works"}
                  >
                    How it Works
                  </a>{" "}
                  page for real life examples.
                </p>
                <ul className="inline-flex flex-col space-y-2.5 mb-8">
                  <li className="flex items-center">
                    <svg
                      className="shrink-0 mr-3"
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="fill-blue-100"
                        cx="10"
                        cy="10"
                        r="10"
                      />
                      <path
                        className="fill-blue-500"
                        d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                      />
                    </svg>
                    <span>Your emails never leave your Gmail account</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="shrink-0 mr-3"
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="fill-blue-100"
                        cx="10"
                        cy="10"
                        r="10"
                      />
                      <path
                        className="fill-blue-500"
                        d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                      />
                    </svg>
                    <span>We don't share any of your data, ever</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="shrink-0 mr-3"
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        className="fill-blue-100"
                        cx="10"
                        cy="10"
                        r="10"
                      />
                      <path
                        className="fill-blue-500"
                        d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                      />
                    </svg>
                    <span>
                      Too Phishy is certified by Google's privacy team
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="absolute inset-0 max-w-6xl mx-auto px-4 sm:px-6">
            <div aria-hidden="true" className="absolute inset-0 -z-10">
              <canvas
                width="1152"
                height="239"
                style={{ width: "1152px", height: "239px" }}
              ></canvas>
            </div>
          </div>
          <div className="py-12 md:py-16">
            <div className="overflow-hidden">
              <div className="clients-carousel swiper-container relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-slate-900 after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-slate-900">
                <div className="swiper-wrapper !ease-linear select-none items-center">
                  <div className="swiper-slide !w-auto">
                    <img
                      src="images/noun-magnifying-2011176-FFFFFF.svg"
                      alt="Attachment Analysis"
                      width="60"
                      height="25"
                    />
                  </div>
                  <div className="swiper-slide !w-auto">
                    <img
                      className="mt-1"
                      src="images/noun-link-49479-FFFFFF.svg"
                      alt="Suspicious Link Tracing"
                      width="107"
                      height="33"
                    />
                  </div>
                  <div className="swiper-slide !w-auto">
                    <img
                      src="images/noun-zig-zag-1677189-FFFFFF.svg"
                      alt="Header Analysis"
                      width="110"
                      height="21"
                    />
                  </div>
                  <div className="swiper-slide !w-auto">
                    <img
                      src="images/github-mark-white.svg"
                      alt="Open Source"
                      width="60"
                      height="25"
                    />
                  </div>
                  <div className="swiper-slide !w-auto">
                    <img
                      className="mt-1"
                      src="images/noun-attachment-1052154-FFFFFF.svg"
                      alt="Content Analysis"
                      width="107"
                      height="33"
                    />
                  </div>
                  <div className="swiper-slide !w-auto">
                    <img
                      className="mt-1"
                      src="images/noun-envelope-5496142-FFFFFF.svg"
                      alt="Email Domain Flagging"
                      width="70"
                      height="25"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative" data-aos="fade-up">
        <div
          aria-hidden="true"
          className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-none -z-10 mt-5"
        >
          <img
            className="max-w-none"
            height="350"
            src="./images/testimonial-illustration.svg"
            width="1440"
          />
        </div>

        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12">
            <div className="max-w-3xl mx-auto">
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <div className="inline-flex mb-3">
                  <img
                    alt="Testimonial 01"
                    className="rounded-full"
                    height="100"
                    src="images/testimonial-01.jpg"
                    width="100"
                  />
                </div>
                <div className="font-bold text-lg mb-1">
                  "Too Phishy is great - it helped me get out of a $450 scam on
                  Venmo."
                </div>
                <div className="text-slate-400 mb-3">
                  Landon Dean <span className="text-gray-400">-</span> Senior
                  Trading Specialist,{" "}
                  <a
                    className="text-blue-500 font-bold hover:text-blue-600"
                    href="https://www.thetradedesk.com/us"
                  >
                    The Trade Desk
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-12">
              <div className="max-w-3xl mx-auto">
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <div className="inline-flex mb-3">
                    <img
                      alt="Testimonial 02"
                      className="rounded-full"
                      height="100"
                      src="images/testimonial-02.jpg"
                      width="100"
                    />
                  </div>
                  <div className="font-bold text-lg mb-1">
                    "Really easy to use. Convenient and a great sanity check to
                    help avoid phishing. Glad I found it."
                  </div>
                  <div className="text-slate-400 mb-3">
                    Sasha Burger <span className="text-gray-400">-</span>{" "}
                    Associate,{" "}
                    <a
                      className="text-blue-500 font-bold hover:text-blue-600"
                      href="https://www.debevoise.com"
                    >
                      Debevoise & Plimpton
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="py-12">
              <div className="max-w-3xl mx-auto">
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <div className="inline-flex mb-3">
                    <img
                      alt="Testimonial 03"
                      className="rounded-full"
                      height="100"
                      src="images/testimonial-03.jpg"
                      width="100"
                    />
                  </div>
                  <div className="font-bold text-lg mb-1">
                    "Manages to warn of potentially suspicious emails that make
                    it through Google's spam filters. Even auto-investigates
                    links embedded in the email."
                  </div>
                  <div className="text-slate-400 mb-3">
                    Matthew Altonji <span className="text-gray-400">-</span>{" "}
                    Borough Analyst,{" "}
                    <a
                      className="text-blue-500 font-bold hover:text-blue-600"
                      href="https://www.nyc.gov/site/dep/index.page"
                    >
                      NYC Department of Environmental Protection
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
